<template>
  <div>
    <div class="container-transaction">
      <div id="payment" class="container-transaction-vertical-center">
        <div v-if="model.collect && model.collect.status" id="payment">
          <div v-cus-loading="loading.main" class="transaction-summary">
            <table
              class="wrapper"
              width="100%"
              height="100%"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
            >
              <tr>
                <td align="center" width="100%">
                  <table
                    class="sm-w-full table-main"
                    width="600"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                  >
                    <tr>
                      <td
                        class="sm-inline-block bordered-shadow"
                        style="display: none"
                        colspan="2"
                      >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="sm-hidden print-hidden title-message"
                        width="160"
                      >
                      </td>
                      <td
                        class="sm-p-20 bordered-shadow content"
                        align="left"
                        :class="`border-${toKebabCase(
                          model.collect.status.status,
                        )}`"
                        bgcolor="#ffffff"
                        valign="top"
                      >
                        <table
                          class="sm-p-20 content header-images"
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tr>
                            <td class="sm-inline-block" align="center">
                              <img
                                style="border: 0; display: block"
                                src="@/assets/img/svg/logo-aliaddo.svg"
                                height="60"
                              />
                            </td>
                          </tr>
                        </table>

                        <table
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tr>
                            <td width="80%">
                              <h1
                                :class="`text-${toKebabCase(
                                  model.collect.status.status,
                                )}`"
                                class="sm-text-lg text-title"
                              >
                                {{ $tc('transaction') }}
                                {{
                                  $tc(toCamelCase(model.collect.status.status))
                                }}
                              </h1>
                            </td>
                            <td
                              class="print-hidden"
                              style="text-align: right"
                              width="20%"
                              align="left"
                            >
                              <el-button
                                type="text"
                                circle
                                size="medium"
                                @click="onPrint('payment')"
                              >
                                <span
                                  :class="`text-${toKebabCase(
                                    model.collect.status.status,
                                  )}`"
                                >
                                  <i
                                    class="fad fa-print fa-2x"
                                    :title="$tc('print')"
                                  ></i>
                                </span>
                              </el-button>
                            </td>
                          </tr>
                        </table>

                        <table
                          class="sm-leading-32 sub-content"
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tbody>
                            <tr>
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('companyName') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{ $tc('aliaddo') }}
                              </td>
                            </tr>
                            <tr>
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('nit') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{ $tc('aliaddoNit') }}
                              </td>
                            </tr>
                            <tr v-if="model.collect.request">
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('customer') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{ model.collect.request.payer.name }}
                                {{ model.collect.request.payer.surname }}
                              </td>
                            </tr>
                            <tr v-if="model.collect.payment">
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('reference') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{ model.collect.payment.reference }}
                              </td>
                            </tr>
                            <tr>
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('status') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{
                                  $tc(toCamelCase(model.collect.status.status))
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('message') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                ({{ model.collect.status.reason }})-({{
                                  model.collect.status.message
                                }})
                              </td>
                            </tr>
                            <tr v-if="model.collect.request">
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('description') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{ model.collect.request.payment.description }}
                              </td>
                            </tr>
                            <tr class="responsive text">
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('iva', 1) }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{
                                  model.collect.placeToPay.placeToPayIvaValue
                                    | formatToCurrencyWithIsoCode
                                }}
                              </td>
                            </tr>
                            <tr class="responsive text">
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('iva', 1) }} ({{ $tc('base', 2) }})
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{
                                  model.collect.placeToPay.placeToPayIvaBase
                                    | formatToCurrencyWithIsoCode
                                }}
                              </td>
                            </tr>
                            <tr v-if="model.collect.payment">
                              <td
                                :class="`text-${toKebabCase(
                                  model.collect.status.status,
                                )}`"
                                class="responsive text-content"
                                width="50%"
                              >
                                {{ $tc('cus') }}
                              </td>
                              <td
                                :class="`text-${toKebabCase(
                                  model.collect.status.status,
                                )}`"
                                class="responsive text-content"
                                width="50%"
                                align="left"
                              >
                                {{ model.collect.payment.authorization }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                :class="`text-${toKebabCase(
                                  model.collect.status.status,
                                )}`"
                                class="responsive text-content"
                                width="50%"
                              >
                                {{ $tc('date') }}
                              </td>
                              <td
                                :class="`text-${toKebabCase(
                                  model.collect.status.status,
                                )}`"
                                class="responsive text-content"
                                width="50%"
                                align="left"
                              >
                                {{
                                  model.collect.status.date | formatToDateTime
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tr>
                            <td style="padding-top: 18px">
                              <div
                                :class="`background-${toCamelCase(
                                  model.collect.status.status,
                                )}`"
                                class="line-separator"
                                style="line-height: 15px"
                              >
                                &zwnj;
                              </div>
                            </td>
                          </tr>
                        </table>

                        <table
                          v-if="model.collect.payment"
                          class="sm-leading-32 sub-content"
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tbody>
                            <tr class="text-footer">
                              <td
                                :class="`text-${toKebabCase(
                                  model.collect.status.status,
                                )}`"
                                class="responsive text-footer-content"
                                width="50%"
                              >
                                {{ $tc('value') }}
                              </td>
                              <td
                                :class="`text-${toKebabCase(
                                  model.collect.status.status,
                                )}`"
                                class="responsive text-footer-content"
                                width="50%"
                                align="right"
                              >
                                {{
                                  model.collect.payment.amount.to.total
                                    | formatToCurrencyWithIsoCode
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          v-else
                          class="sm-leading-32 sub-content"
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tbody>
                            <tr class="text-footer">
                              <td
                                :class="`text-${toKebabCase(
                                  model.collect.status.status,
                                )}`"
                                class="responsive text-footer-content"
                                width="50%"
                              >
                                {{ $tc('value') }}
                              </td>
                              <td
                                :class="`text-${toKebabCase(
                                  model.collect.status.status,
                                )}`"
                                class="responsive text-footer-content"
                                width="50%"
                                align="right"
                              >
                                {{
                                  model.collect.placeToPay.placeToPayAmountTotal
                                    | formatToCurrencyWithIsoCode
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tr>
                            <td style="padding-top: 18px; padding-bottom: 18px">
                              <div
                                :class="`background-${toCamelCase(
                                  model.collect.status.status,
                                )}`"
                                class="line-separator"
                              >
                                &zwnj;
                              </div>
                            </td>
                          </tr>
                        </table>

                        <table
                          v-if="
                            model.collect &&
                            model.collect.status &&
                            model.collect.status.status === 'APPROVED'
                          "
                          class="sm-w-full table-main footer-images"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tr>
                            <td class="responsive text-content" aling="center">
                              <el-button
                                size="mini"
                                type="text"
                                class="payment-button"
                                :class="`background-${toCamelCase(
                                  model.collect.status.status,
                                )}`"
                                @click="onReturnService()"
                              >
                                {{ $tc('returnService') }}
                              </el-button>
                            </td>
                          </tr>
                        </table>

                        <table
                          class="sm-w-full table-main footer-images"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tr>
                            <td
                              class="responsive text-content"
                              align="left"
                              width="50%"
                            >
                              <img
                                src="https://hub.aliaddo.com/img/place_to_pay/MASTERCARD.png"
                                height="25"
                                alt="Mastercard"
                              />
                              <img
                                src="https://hub.aliaddo.com/img/place_to_pay/PSE.png"
                                height="25"
                                alt="Pse"
                              />
                              <img
                                src="https://hub.aliaddo.com/img/place_to_pay/VISA.png"
                                height="25"
                                alt="Visa"
                              />
                            </td>
                            <td class="responsive text-content">
                              <address>
                                <a href="mailto:#">facturacion@aliaddo.com</a>
                              </address>
                            </td>
                            <td
                              class="responsive text-content"
                              align="right"
                              width="50%"
                            >
                              <a
                                href="https://www.placetopay.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://hub.aliaddo.com/img/place_to_pay/Logo_PlacetoPay.png"
                                  height="25"
                                />
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div v-else id="payment-failed">
          <div
            v-if="model.collect && model.collect.status"
            v-cus-loading="loading.main"
            class="transaction-summary"
          >
            <table
              class="wrapper"
              width="100%"
              height="100%"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
            >
              <tr>
                <td align="center" width="100%">
                  <table
                    class="sm-w-full table-main"
                    width="600"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                  >
                    <tr>
                      <td
                        class="sm-inline-block bordered-shadow"
                        style="display: none"
                        colspan="2"
                      >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="sm-hidden print-hidden title-message"
                        width="160"
                      >
                      </td>
                      <td
                        class="sm-p-20 bordered-shadow content rejected"
                        align="left"
                        bgcolor="#ffffff"
                        valign="top"
                      >
                        <table
                          class="sm-p-20 content header-images"
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tr>
                            <td class="sm-inline-block" align="center">
                              <img
                                style="border: 0; display: block"
                                src="@/assets/img/svg/logo-aliaddo.svg"
                                height="60"
                              />
                            </td>
                          </tr>
                        </table>

                        <table
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tr>
                            <td width="80%">
                              <h1 class="sm-text-lg text-title text-failed">
                                {{ $tc('collectFailded') }}
                              </h1>
                              <p
                                class="sm-text-xs text-title-message text-failed"
                              >
                                {{ $tc('collectFaildedMessage') }}
                              </p>
                            </td>
                            <td
                              class="print-hidden"
                              style="text-align: right"
                              width="20%"
                              align="left"
                            >
                              <el-button
                                type="text"
                                circle
                                size="medium"
                                @click="onPrint('payment-failed')"
                              >
                                <span class="text-failed">
                                  <i
                                    class="fad fa-print fa-2x"
                                    :title="$tc('print')"
                                  ></i>
                                </span>
                              </el-button>
                            </td>
                          </tr>
                        </table>

                        <table
                          class="sm-leading-32 sub-content"
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tbody>
                            <tr v-if="model.collect.status">
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('status') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{ $tc(model.collect.status.status) }}
                              </td>
                            </tr>
                            <tr v-if="model.collect.status">
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('message') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                ({{ model.collect.status.reason }})-({{
                                  model.collect.status.message
                                }})
                              </td>
                            </tr>
                            <tr v-else-if="model.collect.placeToPay">
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('message') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{
                                  model.collect.placeToPay
                                    .placeToPayStatusMessage
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <br />
        <div id="subscription">
          <div v-cus-loading="loading.main" class="transaction-summary">
            <table
              class="wrapper"
              width="100%"
              height="100%"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
            >
              <tr>
                <td align="center" width="100%">
                  <table
                    class="sm-w-full table-main"
                    width="600"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                  >
                    <tr>
                      <td
                        class="sm-inline-block bordered-shadow"
                        style="display: none"
                        colspan="2"
                      >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="sm-hidden print-hidden title-message"
                        width="160"
                      >
                      </td>
                      <td
                        class="sm-p-20 bordered-shadow content"
                        align="left"
                        :class="`border-${toKebabCase(
                          model.payment.status.status,
                        )}`"
                        bgcolor="#ffffff"
                        valign="top"
                      >
                        <table
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tr>
                            <td width="80%">
                              <h1
                                :class="`text-${toKebabCase(
                                  model.payment.status.status,
                                )}`"
                                class="sm-text-lg text-title"
                              >
                                {{
                                  $tc(
                                    `subscription${toPascalCase(
                                      model.payment.status.status,
                                    )}`,
                                  )
                                }}
                              </h1>
                              <p
                                :class="`text-${toKebabCase(
                                  model.payment.status.status,
                                )}`"
                                class="sm-text-xs text-title-message"
                              >
                                {{
                                  $tc(
                                    `subscription${toPascalCase(
                                      model.payment.status.status,
                                    )}Message`,
                                  )
                                }}
                              </p>
                            </td>
                            <td
                              class="print-hidden"
                              style="text-align: right"
                              width="20%"
                              align="left"
                            >
                              <el-button
                                type="text"
                                circle
                                size="medium"
                                @click="onPrint('subscription')"
                              >
                                <span
                                  :class="`text-${toKebabCase(
                                    model.payment.status.status,
                                  )}`"
                                >
                                  <i
                                    class="fad fa-print fa-2x"
                                    :title="$tc('print')"
                                  ></i>
                                </span>
                              </el-button>
                            </td>
                          </tr>
                        </table>

                        <div style="line-height: 32px">
&zwnj;
</div>
                        <table
                          v-if="
                            model.payment &&
                            model.payment.status &&
                            model.payment.status.status &&
                            ['REJECTED', 'ERROR'].includes(
                              model.payment.status.status,
                            )
                          "
                          width="100%"
                          class="sm-w-full table-main footer-images"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tr>
                            <td align="center" class="responsive text-content">
                              <el-button
                                size="mini"
                                type="text"
                                class="btn btn-payment-button"
                                @click="onRetryPayment()"
                              >
                                {{ $tc('retry') }}
                              </el-button>
                            </td>
                          </tr>
                        </table>

                        <table
                          class="sm-leading-32 sub-content"
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tbody>
                            <tr>
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('status') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                {{
                                  $tc(toCamelCase(model.payment.status.status))
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="responsive text-subtitle" width="50%">
                                {{ $tc('message') }}
                              </td>
                              <td
                                class="responsive text-content"
                                width="auto"
                                align="left"
                              >
                                ({{ model.payment.status.reason }})-{{
                                  model.payment.status.message
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                :class="`text-${toKebabCase(
                                  model.payment.status.status,
                                )}`"
                                class="responsive text-content"
                                width="50%"
                              >
                                {{ $tc('card') }}
                              </td>
                              <td
                                :class="`text-${toKebabCase(
                                  model.payment.status.status,
                                )}`"
                                class="responsive text-content"
                              >
                                ({{
                                  model.payment.placeToPay
                                    .placeToPayFranchiseName
                                }}) ****
                                {{
                                  model.payment.placeToPay
                                    .placeToPayCardLastDigits
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                :class="`text-${toKebabCase(
                                  model.payment.status.status,
                                )}`"
                                class="responsive text-content"
                                width="50%"
                              >
                                {{ $tc('date') }}
                              </td>
                              <td
                                :class="`text-${toKebabCase(
                                  model.payment.status.status,
                                )}`"
                                class="responsive text-content"
                                width="50%"
                                align="left"
                              >
                                {{
                                  model.payment.status.date | formatToDateTime
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// #region  vue ...
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'

// #endregion

// #region  app ...
import { mapFilters } from '@/filters/map-filters'

// #endregion

// #region  VueHtmlToPaper ...
const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://unpkg.com/element-ui/lib/theme-chalk/index.css',
    '/css/transaction-result.css',
  ],
}

Vue.use(VueHtmlToPaper, options)

// #endregion

export default {
  name: 'PlaceToPayResult',
  data() {
    return {
      loading: {
        main: false,
      },
      model: {
        collect: {
          payment: {
            amount: {
              factor: 1,
              from: {
                currency: 'COP',
                total: '0',
              },
              to: {
                currency: 'COP',
                total: '0',
              },
            },
            reference: '',
          },
          placeToPay: {
            placeToPayIvaBase: '',
            placeToPayIvaValue: '',
            placeToPayRecurrent: {},
          },
          request: {
            ipAddress: '',
            payer: {
              address: '',
              company: '',
              document: '',
              documentType: '',
              email: '',
              mobile: '',
              name: '',
              surname: '',
            },
            payment: {},
          },
          status: {
            date: '',
            message: '',
            reason: '',
            status: '',
          },
        },
        payment: {
          payment: {
            amount: {
              factor: 1,
              from: {
                currency: 'COP',
                total: '',
              },
              to: {
                currency: 'COP',
                total: '',
              },
            },
            reference: '',
          },
          placeToPay: {
            placeToPayIvaBase: '',
            placeToPayIvaValue: '',
            placeToPayRecurrent: {},
          },
          request: {
            ipAddress: '',
            payer: {
              address: '',
              company: '',
              document: '',
              documentType: '',
              email: '',
              mobile: '',
              name: '',
              surname: '',
            },
            payment: {},
          },
          status: {
            date: '',
            message: '',
            reason: '',
            status: '',
          },
        },
      },
    }
  },
  mounted() {
    this.processPayment()
  },
  methods: {
    ...mapFilters(['toCamelCase', 'toKebabCase', 'toPascalCase']),
    onPrint(idTag) {
      this.$htmlToPaper(idTag)
    },
    onReturnService() {
      const _this = this
      window.location.replace(
        `https://${_this.model.payment.service.serviceDomain}`,
      )
    },
    onRetryPayment() {
      const _this = this
      window.location.replace(_this.model.urlRetryPayment)
    },
    processPayment() {
      const _this = this
      _this.loading.main = true
      _this.$http
        .patch(
          `payments/placeToPay/${_this.$route.params.placeToPayReference}/subscription/success`,
        )
        .then(response => {
          console.log('%c⧭', 'color: #ffa640', response)
          _this.model.urlRetryPayment = response.body.urlRetryPayment
          _this.model.payment = response.body.payment
          _this.model.collect = response.body.collect
        })
        .finally(() => {
          _this.loading.main = false

          return ''
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/transactions/transaction-result.scss';
</style>
